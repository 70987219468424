import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [

  //Login
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Auth/Login')
  },
  {
    path: '/course/quizWeb',
    name: 'course-quizWeb',
    component: () => import('../views/Course/quizWeb.vue')
  },
  // Home
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home/Index_home.vue')
  },
  // Home/Banner
  {
    path: '/home/banner',
    name: 'home-banner',
    component: () => import('../views/Home/Banner.vue')
  },
  // Library
  {
    path: '/library',
    name: 'library',
    component: () => import('../views/Library/index.vue')
  },
  {
    path: '/library/:name',
    name: 'library-:name',
    component: () => import('../views/Library/_subLibrary/subIndex.vue')
  },
  {
    path: '/library/:name/:subName',
    name: 'library-:name-:subName',
    component: () => import('../views/Library/_subLibrary/_path')
  },
  // {
  //   path: '/library/:articlename',
  //   name: 'library-:articlename',
  //   component: () => import('../views/Library/_subLibrary/_path/_article/articleView.vue')
  // },
  {
    path: '/Library/_subLibrary/_path/_article/articleView',
    name: 'article-detail',
    component: () => import('../views/Library/_subLibrary/_path/_article/articleView.vue')
  },
  //LessonLearn
  {
    path: '/lessonlearn',
    name: 'lessonlearn',
    component: () => import('../views/LessonLearn/Index.vue')
  },
  {
    path: '/lessonlearn/:name',
    name: 'lessonlearn-:name',
    component: () => import('../views/LessonLearn/LessonLearnDetail.vue')
  },
  {
    path: '/course',
    name: 'course',
    component: () => import('../views/Course/course.vue')
  },
  {
    path: '/course/quiz',
    name: 'course-quiz',
    component: () => import('../views/Course/quiz.vue')
  },
  {
    path: '/course/article',
    name: 'course-article',
    component: () => import('../views/Course/CourseArticleView.vue')
  },
  // Commuinty
  {
    path: '/community',
    name: 'community',
    component: () => import('../views/Community/index.vue')
  },
  //Event
  {
    path: '/event',
    name: 'event',
    component: () => import('../views/Event/index.vue')
  },
  {
    path: '/event/:name',
    name: 'event-:name',
    component: () => import('../views/Event/viewEvent.vue')
  },

  {
    path: '/feedbackbox',
    name: 'feedbackbox',
    component: () => import('../views/Feedback/index.vue')
  },
  // Favourite
  {
    path: '/favourite',
    name: 'favourite',
    component: () => import('../views/Favourite/index.vue')
  },
  // Inbox
  {
    path: '/inbox',
    name: 'inbox',
    component: ()=> import('../views/Inbox/index_Inbox.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //const token = localStorage.getItem("vivek_token");
  const token = sessionStorage.getItem("token");
  //if (to.path.toLowerCase() == "/course/quizweb") next("/course/quizWeb");
  if(to.path.toLowerCase()=="/course/quizweb" || to.path.toLowerCase()=="/en/course/quizWeb") next();
  else if (!token && to.path != "/login") next("/login");
  else next();
});

export default router

<template>
    <div>
        <v-dialog width="500" v-model="dialog">
            <v-card>
            <v-card-title
          style="font-size: 20px; color: #363636; height: 80px"
          class="justify-center"
          > <v-icon class="mr-5" style="color: #A6CC39;">mdi-checkbox-marked-circle</v-icon> {{text}}</v-card-title>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    props:{
        title:{
            type: String,
            default: null
        },
        text:{
            type: String,
            default: null
        },
        show:{
            type: Boolean,
            default: false
        }
    },
    computed:{
        dialog:{
            get(){
                return this.show
            },
            set(){
                this.$emit('close');
            }
        }
    }
}
</script>
<style scoped>
>>> .v-dialog {
  border-radius: 16px !important;
}
</style>